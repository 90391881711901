import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { hasSuperCard } from 'utils/user'

export default function Container ({ children }) {
  const router = useRouter()
  const [basePath, setBasePath] = useState('')
  const [cookiesNotifVisible, setCookiesNotifVisible] = useState(true)

  const state = useStoreState(s => ({
    me: s.me
  }))

  const footerMenu = [
    {
      base: 'order-now',
      title: 'Order now!',
      link: '/order-now/categories/more'
    },
    {
      base: 'catalog',
      title: 'Menu',
      link: '/catalog/categories/more'
    },
    {
      base: 'promos',
      title: 'Promos',
      link: '/promos'
    },
    {
      base: 'order-tracker',
      title: 'Order Tracker',
      link: '/my-orders'
    },
    {
      base: 'supercard',
      title: 'Supercard',
      link: hasSuperCard() ? '/supercard/my' : '/supercard/buy'
    },
    {
      base: 'about-us',
      title: 'About Us',
      link: '/about-us'
    },
    {
      base: 'contact-us',
      title: 'Contact Us',
      link: '/contact-us'
    },
    {
      base: 'store-locator',
      title: 'Store Locator',
      link: '/store-locator'
    }
  ]

  const legalLinks = [
    { title: 'Privacy Policy', link: '/privacy-policy' },
    { title: 'Terms and Conditions', link: '/terms-and-conditions' }
    // { title: 'Facebook Data Deletion', link: '/facebook-data-deletion' }
  ]

  useEffect(() => {
    setBasePath(router.pathname.split('/')[1])
    if (typeof localStorage.getItem('cookiesNotf') !== 'undefined' && localStorage.getItem('cookiesNotif')) {
      setCookiesNotifVisible(JSON.parse(localStorage.getItem('cookiesNotif')))
    }
  }, [])

  const handleCloseCookiesNotif = () => {
    setCookiesNotifVisible(false)
    localStorage.setItem('cookiesNotif', false)
  }

  return children({
    basePath,
    state,
    handleCloseCookiesNotif,
    cookiesNotifVisible,
    footerMenu,
    legalLinks
  })
}
