import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Space, Image } from 'antd'
import { StyledMainHeader } from 'components/PageHeader/styles'
import { DeliveryBanner } from 'components/PromoBanner'
import Link from 'next/link'
import { COPYRIGHT, socialMediaLinks } from 'utils/constants'
import ViewContainer from './container'
import { StyledCookieNotification, StyledCopyRightWrapper, StyledFooterWrapper, StyledLegalLinksWrapper } from './styles'

export default function Index () {
  return (
    <ViewContainer>
      {({ basePath, state, handleCloseCookiesNotif, cookiesNotifVisible, footerMenu, legalLinks }) => {
        return (
          <StyledFooterWrapper>
            {typeof state.me.payload !== 'undefined' && state.me.payload !== null && cookiesNotifVisible && (
              <StyledCookieNotification justify='center' align='middle'>
                <Col
                  xs={{ span: 14, offset: 2 }}
                  md={{ span: 14, offset: 2 }}
                  lg={{ span: 14, offset: 2 }}
                  xxl={{ span: 14, offset: 2 }}
                >
                  We use cookies on this site to enhance your user experience. By clicking any link on this page you are giving your consent for us to set cookies.
                </Col>
                <Col xs={0} md={2} offset={1}>
                  <Button onClick={() => handleCloseCookiesNotif()} style={{ width: '100%' }} type='primary'>Okay</Button>
                </Col>
                <Col span={2} offset={1}>
                  <Link href='/privacy-policy'>
                    <Image style={{ cursor: 'pointer' }} src='/images/peri-seal.png' alt='' preview={false} width={39} height={69} />
                  </Link>
                </Col>
                <Col span={2}>
                  <CloseOutlined onClick={() => handleCloseCookiesNotif()} />
                </Col>
              </StyledCookieNotification>
            )}
            {basePath !== 'auth' && <StyledMainHeader align='top' justify='space-between' footer='1'>
              <Col
                xs={{ span: 0, offset: 0 }}
                sm={{ span: 0, offset: 0 }}
                md={{ span: 0, offset: 0 }}
                lg={{ span: 7, offset: 0 }}
                xl={{ span: 8, offset: 0 }}>
                <Link href='/'>
                  <img className='branding' src='/images/logos/peri-brand.png' alt='Peri Peri' />
                </Link>
              </Col>
              <Col className='social-media-footer' xs={{ span: 24 }} md={{ span: 0 }}>
                <Space direction='horizontal' size={20}>
                  {socialMediaLinks.map(social => (
                    <a key={social.code} href={social.link} rel='noreferrer' target='_blank'>
                      <Button shape='circle' icon={social.icon} />
                    </a>
                  ))}
                </Space>
              </Col>
              <Col className='menu-wrapper'
                xs={{ span: 24, offset: 0 }}
                sm={{ span: 24, offset: 0 }}
                md={{ span: 24, offset: 0 }}
                lg={{ span: 16, offset: 0 }}
                xl={{ span: 16, offset: 0 }}>
                {footerMenu.map((menu) => (
                  <Link href={menu.link} key={menu.title}>
                    <a className={menu.base === basePath ? 'active' : 'menus'}>
                      {menu.title}
                    </a>
                  </Link>
                ))}
              </Col>
            </StyledMainHeader>}
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0 }}><DeliveryBanner /></Col>
            <StyledCopyRightWrapper justify='center' align='middle'>
              <Space direction='horizontal' size={11}>
                {socialMediaLinks.map(social => (
                  <a key={social.code} href={social.link} rel='noreferrer' target='_blank'>
                    <Button shape='circle' icon={social.icon} />
                  </a>
                ))}
                <p>| {COPYRIGHT}</p>

                {legalLinks.map(link => (
                  <StyledLegalLinksWrapper key={link.link}>
                    <Link href={link.link}>
                      {link.title}
                    </Link>
                  </StyledLegalLinksWrapper>
                ))}
              </Space>
            </StyledCopyRightWrapper>
          </StyledFooterWrapper>
        )
      }}
    </ViewContainer>
  )
}
