import { Input, Layout, Menu } from 'antd'
import OtpInput from 'react-otp-input'
import styled from 'styled-components'

export const StyledSearch = styled(Input)`
  background-color: var(--white) !important;
  box-sizing: border-box;
  height: 52px;
  width: fill-available;
  max-width: 1030px;
  border: 1px solid #b5b5b5;
  box-shadow: 0 6px 13px 9px rgba(0, 0, 0, 0.08);
  border: none !important;
  padding-left: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;

  &.ant-input-affix-wrapper {
    border-radius: 15px;
  }

  .ant-input-prefix {
    color: var(--orange-peel);
    cursor: pointer;
  }

  .ant-input {
    margin-left: 20px;
  }

  .ant-input:focus {
    border: none !important;
  }

  @media only screen and (max-width: 820px) {
    width: 100%;
    border-radius: 5px !important;
  }
`

export const StyledOtpInput = styled(OtpInput)`
  height: 100px;
  width: 90px;
  margin-right: 10px;
  text-align: center;
  justify-content: center;
  font-size: 35px;
  font-family: KreonBold;
  background-color: var(--cream) !important;
  border-radius: 10px;
  border: 1px solid var(--grey);
  input {
    background-color: var(--cream) !important;
    border: none !important;
  }
  :focus {
    box-shadow: none !important;
    outline: var(--cream) !important;
  }

  @media only screen and (max-width: 768px) {
    height: 60px;
    width: 50px;
    font-size: 24px;
    border-radius: 5px;
    margin-right: 5px;
  }
`
// Global styles for main layout elements
export const StyledLayout = styled(Layout)`
overflow: hidden;
// position: relative;
background-image: url('/images/bg/catalog-bg.png');
background-color: var(--cream);
.product-box {
  border-radius: 10px;
  box-shadow: 0 22px 24px -30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;

  .ribbon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .product-img {
    .ant-image {
      overflow: hidden;
    }
    img {
      object-fit: cover;
      border-radius: 10px;
      margin-left: -20px;
    }
  }
  .main-product-description {max-height: 60px;}
  .product-description {max-height: 85px;}
  .main-product-description, .main-product-title, , .product-description {
    margin-top: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  
  .main-product-title {
    line-height: 60px;
    min-height: 110px;
  }

  .main-product-img {
      .ant-image {
        width: 100%;
        height: 210px;
        overflow: hidden;
      }
      img {
        margin-top: -60px;
      }
    }

  .mp-footer-container {
    margin-bottom: 10px;
  }
  
  .main-product-starts-at {
    /* background: #F8F7F5; */
    height: 49px;
    text-align: center;
    /* padding-left: 10px; */
  }
}

@media (max-width: 768px) {
  .mp-footer-container {
    .mp-btn {
      margin-top: 10px;
      a {
        button {
          width: 100%;
        }
      }
    }
  }
  .p-btn a button span {
    font-size: 8px;
  }
  .p-price span {
    font-size: 14px;
  }
}

@media (max-width: 540px) {
  .mp-footer-container {
    .mp-btn {
      margin-top: 0px;
    }
    padding-bottom: 20px;
  }
  .p-btn a button span {
    font-size: 12px;
  }
  .p-price span {
    font-size: 20px;
  }
}

@media (max-width: 414px) {
  .mp-footer-container {
    padding-bottom: 20px;
    .mp-btn a button {
      width: 100px;
    }
  }
}
`

export const StyledDynamicBGPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.bg});
  position: relative;
  padding: 34px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--cream);
  min-height: 100vh;
`

export const StyledGlobalLayout = styled.div`
  min-height: 100vh;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 576px) {
    background-size: auto;
  }
`
export const StyledModalBanner = styled.div`
  background-image: url('/images/bg/nav-bg.png');
  background-size: cover;
  height: 113px;
  border-radius: inherit;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: var(--cream);
    width: 100%;
    text-align: center;
  }
`
export const StyledMenu = styled(Menu)`
  background-color: var(--white);
  border-radius: inherit;
  padding: 23px 0 0 25px;
  font-size: 20px;
  >.ant-menu-item:hover {
    color: var(--black);
    border: none !important;
  }
  @media only screen and (max-width: 576px) {
    justify-content:center;
    font-size: 16px;
    padding: 12px 16px 0 16px;
  }
`
export const Styled3ColsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 62px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
