import styled from 'styled-components'
import { Row } from 'antd'

export const StyledFooterWrapper = styled(Row)`
  position: relative;
  .social-media-footer {
    text-align: center;
    margin-top: 25px;
    .ant-btn {
      width: 41px;
      height: 41px;
      border: none !important;
      svg {
        color: var(--orange-peel) !important;
      }
    }
  }
`
export const StyledMainFooter = styled(Row)`
  background-image: url('/images/bg/nav-bg-footer.png');
  background-size: 100% 100%;
  height: 110px;
  width: 100%;
  background-color: inherit !important;
  background-repeat: no-repeat;
  padding: 30px 22px 46px 22px;
  align-items: center;

  a {
    color: var(--cream);
    margin-right: 15px;
  }

  @media (max-width: 1030px) {
    flex-direction: column;
  }
`

export const StyledCopyRightWrapper = styled(Row)`
  background-color: var(--cognac);
  height: 40px;
  width: 100%;
  color: var(--cream);
  p {
    margin: 0 !important;
  }
  .ant-btn {
    height: 17px;
    width: 17px;
    min-width: 17px !important;
    background-color: #ffefd1;
    svg {
      font-size: 11.5px !important;
      color: var(--cognac);
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
export const StyledCookieNotification = styled(Row)`
  position: fixed;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 103px;
  bottom: 0;
  color: var(--white);
  transition: 0.4s ease-in-out;
  .anticon {
    font-size: 25px;
  }

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 24px 0;
    align-items: start;
  }
`
export const StyledLegalLinksWrapper = styled.div`
  :first-child {
    &:before {
      content: ' | ';
    }
  }
`
